.App, body {
  text-align: center;
  color: rgb(97, 94, 94);
  letter-spacing: 0.045em;
  height: 100% !important;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

a:hover {
  text-decoration: none;
}
/* ---------Navbar-------------- */

.autofit {
  width: 100%;
  margin: auto !important;
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px !important;
  border-top: 1px solid #DCDCDC;
  z-index: 3;
}

.MuiBottomNavigationAction-root {
  margin: 0 30px !important;
}

.MuiBottomNavigation-root .MuiButtonBase-root {
  width: 20% !important;
  padding: 0 !important;
}

.MuiBottomNavigationAction-iconOnly {
  font-size: 18px !important;
}

.MuiBottomNavigation-root .MuiButtonBase-root:hover {
  text-decoration: none;
}

.Mui-selected .MuiBottomNavigationAction-wrapper {
  border-top: 4px solid #EC2034 !important;
  color: #EC2034;
}

.MuiBottomNavigationAction-wrapper:hover, .MuiBottomNavigationAction-root:hover {
  color: #EC2034;
}

.MuiBottomNavigationAction-label,
.Mui-selected .MuiBottomNavigationAction-label {
  font-size: 12px !important;
  letter-spacing: 0.03em;
  font-weight: 700;
  font-family: Arial !important;
  margin-top: 3px;
}

@media (max-width: 968px) {
  .MuiBottomNavigationAction-label, .MuiTouchRipple-root, .Mui-selected .MuiBottomNavigationAction-label {
    font-size: 10px !important;
    margin-top: 0px !important;
  }

  .autofit {
    height: 50px !important;
  }

  .Mui-selected .MuiBottomNavigationAction-wrapper {
    border-top: 2px solid #EC2034 !important;
  }

  .MuiBottomNavigationAction-root {
    margin: 0  !important;
  }

  .MuiBottomNavigationAction-root .MuiSvgIcon-root,
  .MuiBottomNavigationAction-root svg {
    margin-top: 3px;
  }

  .slidecontain::-webkit-scrollbar {
    background: transparent;
  }
}

/* ------- Index page CSS -------- */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.header {
  background: #FFFFFF;
}

.efelogo {
  height: 100px;
  padding: 30px 0;
}

.logocap {
  font-size: 10px;
  font-weight: bold;
  color: #2E2E2E;
  padding-bottom: 25px;
}

@media (max-width: 968px) {
  .header {
    text-align: left;
  }

  .efelogo {
    height: 70px;
    margin-left: 20px;
    padding: 20px 0;
  }

  .logocap {
    font-size: 5px;
    padding-bottom: 10px;
    margin-left: 20px;
  }
}

.pagetitle {
  padding:0;
  font-weight: 900;
}

.tagline {
  font-size: 0.7rem;
  letter-spacing: 0.15em;
}

.subtitle {
  padding: 30px 0 0 20px;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::placeholder {
  letter-spacing: 0.065em;
  font-size: 11px;
  font-style: italic;
}

@media (max-width: 968px) {
  .exhibitor-paper {
    position: relative;
    height: 110px;
    display: flex;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;
    width: 325px;
    text-align: left;
    border-radius: 8px !important;
    box-shadow: 0px 1px 10px rgba(198, 198, 198, 0.25) !important;
  }
}

@media (min-width: 968px) { 
  .exhibitor-paper {
    position: relative;
    height: 120px;
    display: flex;
    align-items: center;
    width: 45%;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: left;
    border-radius: 8px !important;
    box-shadow: 0px 1px 10px rgba(198, 198, 198, 0.25) !important;
  }

  .maindiv-floor {
    position: relative;
    margin: auto;

  }
}

#listbox {
  min-height: calc(80vh - 50px);
  background: #F4F4F4;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2);
}

.vr-logo {
  height: 47px;
  width: 50px;
  margin-bottom: 7px;
}

.vr-logo.md {
  height: 37px;
  width: 40px;
  margin-bottom: 7px;
}

.vr-logo.sm {
  height: 27px;
  width: 30px;
}

.vr-btn {
  margin-left: auto;
  height: 30px;
}

/* ------Carousel------- */
.hero-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px 20px;
}

.slide-wrapper {
  position:relative;
  background: white;
  margin: auto;
}

.slidebox {
  margin-right: 20px;
}

.left-btn {
  left: 14%;
}

.right-btn {
  right: 14%;
}

.left-btn, .right-btn {
  position: absolute;
  top: 40%;
  padding: 0;
  background: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 968px) {
  .hero-wrapper {
    max-width: 100%;
    padding: 0 0 20px 10px;
  }

  .left-btn, .right-btn {
    display: none;
  }

  .slidecontain {
    overflow-x: auto;
    display: -ms-flexbox;
  }

  .slidebox {
    margin: 0 10px;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none !important;
  }

  .swiper-image {
    height: 100px !important;
    width: 100px !important;
  }
}

.slideimage {
  object-fit: cover !important;
  height: 100px;
  border-radius: 8px;
  margin: auto;
}

@media (min-width: 968px) { 
  .slide-wrapper {
    padding: 0 15% 20px ;
  }

  .slideimage {
    height: 150px;
  }

  .slidecontain {
    overflow: hidden;
    display: -ms-flexbox;
  }
}

.carousel-indicators {
  z-index: 10 !important;
}

.swiper-image {
  width: 100%;
  height: 150px;
  width: 150px;
  object-fit: cover;
  
  border-radius: 8px;
}

.swiper-button-next, .swiper-button-prev {
  display: flex;
  align-items: center;
  margin: 0 -8px;
  height: 27px !important;
  background-image: none !important;
  background-color: white;
  border-radius: 50%;
  padding: 1px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/*------------------ Search Bar CSS ------------------*/
.searchbar{
  position:relative;
  margin-top: 20px;
}

input {
  border: none;
  background: #F2F2F2;
  border-radius: 18px;
  padding: 4px 40px 4px 20px;
  width: 80%;
  outline: none;
  margin: auto;
  background-image: url("https://img.icons8.com/search");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 95% 8px;
  height: 34px;
}

@media (min-width: 968px) { 
  .input-m {
    display: none;
  }
}

@media (max-width: 968px) { 
  .input-d {
    display: none;
  }

  .dropdown-control {
    font-size: 10px !important;
  }
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  width: 70%;
  z-index: 1000;
  font-size: 12px;
}

.suggestions {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 180px;
  overflow-y: auto;
  padding-left: 0;
  width: 70%;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1000;
  font-size: 13px;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: rgb(231, 231, 231);
  cursor: pointer;
  font-weight: 500;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #EAEAEA;
}

.filter-main-title {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #212121;
  max-width: 900px;
  margin: auto;
  align-items: center;
}

.filter-sub-title {
  text-align: left;
  font-size: 10px;
  font-weight: normal;
  line-height: 100%;
  color: #212121;
  max-width: 900px;
  margin: auto;
  align-items: center;
}

.filter-bar {
  max-width: 900px;
  margin: auto;
  padding: 0 10px;
}

.drop-sm {
  font-size: 12px !important;
  text-align: left !important;
  margin-top: 10px;
  max-width: 100px;
}

.goback-btn {
  background: #373737 !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  height: 34px;
  width: 100%;
  padding: 5px 15px;
}

.Dropdown-control {
  padding-right: 30px !important;
  background: #373737 !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
}

.Dropdown-menu {
  border-radius: 8px;
  margin-top: 5px !important;
}

.Dropdown-option {
  font-weight: 500;
  font-size: 12px;
  padding: 10px 10px !important;
  color: rgb(153, 153, 153) !important;
}

.Dropdown-option:not(:last-of-type) {
  border-bottom: 1px solid #EAEAEA;
}

.Dropdown-option:hover {
  background: rgb(231, 231, 231) !important;
}

.drop-md {
  margin: 0 10px;
  width: 40%;
  font-size: 12px !important;
  text-align: left !important;
  margin-top: 10px;
}

.drop-title {
  color: #3B3B3B;
  padding-left: 5px;
  font-size: 10px;
}

.drop-sm-2 {
  font-size: 12px !important;
  text-align: left !important;
  position: absolute;
  width: 125px;
  top: 10px;
  left: 20px;
  z-index: 2;
}

@media (max-width: 968px) {
  .filter-m {
    margin-top: 10px;
    border-top: 1px solid #ECECEC;
  }
}

.zoom-btn-grp {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 2;
}

@media (min-width: 968px) {
  .zoom-btn-grp {
    right: 20%;
  }

  .drop-sm-2 {
    left: 20%;
  }
}

.zoom-btn {
  margin: 2px 4px;
  font-size: 12px !important;
  padding-left: 12px;
  padding-right: 12px;
  border: none;
  background: #3B3B3B;
  height: 34px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.zoom-btn:hover, .zoom-btn:focus {
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.dropdown-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

/*------------------ Floor Map ------------------*/

.floormap {
  position: relative;
  overflow-y: none !important;
  height: calc(100vh - 355px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 355px);
  /* background: #F4F4F4; */
  padding: 50px 0 0 0;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2);
}

.floormap-9 {
  position: relative;
  overflow-y: none !important;
  height: calc(100vh - 255px);
  height: calc(var(--vh, 1vh) * 100 - 255px);
  /* background: #F4F4F4; */
  padding: 50px 0 0 0;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2);
}

.floor_img {
  height: calc(100vh - 325px);
  margin: auto;
}

.zoom-div {
  position: relative;
  align-items: center;
  width: 100%;
  max-height: fit-content;
  margin: auto;
}

.react-transform-component, .react-transform-element {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 968px) {
  .exh-list {
    /* background: #F4F4F4; */
    margin-bottom: 50px !important;
  }

  .exhcar-paper {
    align-self: flex-start;
  }

  .mainhall-padding {
    padding: 25px 0;
  }

  .exh-name-floor {
    cursor: pointer;
    color: #303030;
    font-weight: 700;
    font-size: 3px;
    letter-spacing: 0;
    line-height: 8px;
  }
}

@media (min-width: 968px) {
  .floormap {
    min-height: 80vh !important;
    padding: 10px 0px 190px;
    margin: auto;
    background: none;
  }

  .floormap-9 {
    min-height: 80vh !important;
    padding: 0px 0px 50px;
    margin: auto;
    background: none;
  }

  .react-transform-component, .react-transform-element {
    width: 100% !important;
    height: 100% !important;
  }

  .zoom-div {
    width: 60%;
  }

  .exh-list {
    padding-top: 10px;
    padding-bottom: 10px;
    /* background: #F4F4F4; */
    margin-bottom: 70px !important;
  }

  .exhcar-paper {
    min-width: 270px !important;
    font-size: 12px !important;
    align-self: flex-end;
  }

  .mainhall-padding {
    padding: 75px 0;
  }

  .exh-name-floor {
    cursor: pointer;
    color: #303030;
    font-weight: 700;
    font-size: 5px;
    letter-spacing: 0;
    line-height: 8px;
  }
}

@media (min-width: 1300px) {
  .floormap-9 {
    min-height: 100vh !important;
  }
}

.exhcar-paper {
  margin: 0 5px;
  background: white;
  min-width: 270px;
  text-align: left;
  border-radius: 8px !important;
  box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.08) !important;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.exhcar-active {
  box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.25) !important;
}

.slide-logo-sm {
  height: 80px;
  width: 80px;
  object-fit: scale-down;
  padding: 0 10px;
  border-right: 1px solid #EBEBEB;
}

.red-box {
  background: red;
  width: 100%;
  height: 3px;
  margin-bottom: 5px;
}

.red-box-invisible {
  background: white;
  width: 100%;
  height: 3px;
  margin-bottom: 5px;
}

.exh-small {
  font-weight: normal;
  font-size: 7px;
  margin-top: 2px;
}

.exh-locate-button {
  font-size: 7px;
  width: 60px; 
  border: none;
  padding: 2px 5px;
  background: #E9E9E9;
  border-radius: 3px;
  color: #363636;
  margin-right: 5px;
}

.exh-view-button {
  font-size: 7px;
  width: 60px; 
  border-radius: 3px;
  background: rgba(236, 32, 52, 0.2);
  border: none;
  padding: 2px 5px;
  color: #EC2034;
}

.MuiIcon-fontSizeSmall {
  font-size: 10px !important;
}

.plan:hover path, .plan:hover polygon {
  fill:rgb(255, 105, 105, 1) !important;
}

button:focus {
  outline: none;
}

/* ------------------------ Sliding Pane ------------------------- */
.slide-pane {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  min-width: 100px;
  height: 100%;
  box-shadow: 0px -3px 8px rgba(0,0,0,0.2);
  transition: transform 0.5s;
  will-change: transform;
  z-index: 1000 !important;
}

.slide-pane:focus {
  outline-style: none;
}

.slide-pane_from_bottom {
  height: calc(90vh - 50px);
  margin-top: 10vh;
  transform: translateY(100%);
  border-radius: 15px 15px 0 0;
  overflow-y: hidden !important;
}

.slide-pane_from_bottom #uncontrolled-tab-example {
  position: relative;
  z-index: -1;
}

@media (min-width: 968px) {
  .slide-pane_from_bottom {
    margin-top: 5vh !important;
  }

  .slide-pane {
    max-width: 800px;
    margin: auto;
  }

  .slide-pane_from_bottom {
    border-radius: 15px;
  }

  .slide-top-div, .tab-content {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .slide-logo {
    height: 120px !important;
    width: 120px !important;
  }

  .enq-box {
    border-radius: 0 0 15px 15px;
  }

  .prodcard {
    margin-bottom: 5px !important;
  }

  .prodcard-2 {
    margin: 10px 14px !important;
    width: 200px !important;
    min-height: 230px !important;
  }

  .cate-card {
    margin: 10px 14px !important;
    width: 200px !important;
  }

  .img-btn {
    background: none;
    border: none !important;
    padding: 0;
    height: 150px !important;
    width: 200px;
  }

  .img-prod {
    pointer-events: none;
    height: 150px !important;
    width: 200px !important;
    object-fit: cover;
  }

  .ReactModal__Overlay {
    background: #000 !important;
  }
}

.slide-top-div {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 100;
  border-radius: 15px;
}

.slide-pane_from_bottom.ReactModal__Content--after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.ReactModal__Content--before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
  z-index: 10 !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
  /* background-color: rgba(0,0,0,0.3); */
  transition: background-color 0.5s;
}
.slide-pane__overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0,0,0,0);
}
.slide-pane__header {
  border-radius: 15px 15px 0 0;
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  height: 64px;
  /* border-bottom: 1px solid rgba(0,0,0,0.1); */
  display: none;
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.slide-pane__close {
  background-image: url("https://img.icons8.com/ios/50/000000/close-window.png");
  background-repeat: no-repeat;
  background-size: 23px;  
  margin-left: 20px;
  padding-left: 10px;
  opacity: 1;
  cursor: pointer;
  float: right !important;
}
.slide-pane__close svg {
  opacity: 0;
  width: 12px;
}

.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 0;
  flex: 1 1 auto;
}

.close-btn {
  background:none;
  border: none;
  position: fixed;
  top: 8px;
  right: 15px;
  z-index: 1000;
}

.visit-booth-btn {
  width: 80px;
  height: 25px;
  margin: 10px 15px 5px 0;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: center;
  background: #E9E9E9 !important;
  border-radius: 3px !important;
  color: #363636 !important;
  font-size: 10px !important;
  padding: 5px !important;
}

.visit-booth-btn-2 {
  background: #E9E9E9;
  border-radius: 3px;
  color: #363636;
  font-size: 8px;
  padding: 5px 10px;
  margin-top: 5px !important;
  letter-spacing: 0.03em;
}

.visit-booth-btn-3 {
  background: #E9E9E9;
  border-radius: 3px;
  color: #363636;
  margin-top: 10px;
  font-size: 12px;
  width: 100%;
  padding: 5px 10px;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
}

.visit-booth-btn:hover, .visit-booth-btn-2:hover, .visit-booth-btn-3:hover {
  text-decoration: none !important;
  cursor: pointer;
  color: #363636;
  background: #dbdbdb;
}

.visit-site {
  padding-bottom: 25px;
  color: #FE7070 !important;
}

.visit-href {
  color: #FE7070 !important;
}

.visit-href:hover {
  text-decoration: none !important;
  color: #FE7070 !important;
}

.img-btn-sm {
  background: none;
  border: none;
  padding: 0;
  height: 120px !important;
  width: 100% !important;
}

@media (max-width: 968px) {

  .img-btn {
    background: none;
    border: none;
    padding: 0;
    height: 120px !important;
  }

  .img-prod {
    pointer-events: none;
    height: 120px !important;
    object-fit: cover;
  }

  .prodcard-img {
    height: 120px !important;
  }

  .justcon-center {
    /* justify-content: center; */
    margin: auto;
  }

  .prodcard-sm {
    width: 46% !important;
  }
}

.award-btn {
  border: none;
  background: none;
}

.award-img {
  border-radius: 8px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.img-vid {
  width: 100%;
}

.damn-sticky {
  background: white;
  position: webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
  z-index: 1 !important;
}

.modal-90w, .modal-content, .modal, .modal-100w {
  background: #000;
}

@media (min-width: 968px) {
  .modal-100w {
    width: 70vw !important;
    max-width: none !important;
  }

  .desktop-modal-vid {
    height: 450px;
  }
}

.modal-header {
  border: none;
}

.modal-header .close {
  color: white;
}

.slide-logo {
  height: 110px;
  width: 110px;
  object-fit: scale-down;
  border-right: 1px solid #EBEBEB;
}

.slide-title {
  color: #575757;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.slide-company-desc {
  color: #575757;
  font-size: 14px;
}

.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}

.tab-title {
  border: none;
  padding: 0 10px !important;
  z-index: 100;
  position: sticky;
  top: 158px;
  background: white;
  border-bottom: 1px solid #F2F2F2
}

@media (min-width: 968px) {
  .tab-title {
    top: 168px;
  }
}

.tab-title .nav-link {
  font-size: 13px;
  font-weight: 600;
  border: none;
  color: #959595;
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.nav-link {
  padding: 7px 10px
}

.nav-link:focus {
  outline: none;
}

.tab-title .active {
  border: none;
  color: #FE7070 !important;
  border-bottom: 2px solid #FE7070 !important;
}

.tab-content {
  background: #FFFFFF !important;
  box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.2);
  padding: 20px 25px;
  font-size: 12px;
  text-align: left;
}

.tab-content-subtitle {
  line-height: 50px;
  color: #959595;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-size: 14px;
}

.category-card {
  background: rgba(194, 194, 194, 0);
  border-radius: 4px;
  padding: 2px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.cate-caption {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 0;
  font-size: 12px;
  border-radius: 0 0 4px 4px;
}

.prodcard {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(164, 164, 164, 0.25);
  border-radius: 5px;
  width: 200px;
}

.prodcard-sm {
  background: #FFFFFF;
  box-shadow: 1px 1px 8px 2px rgba(0,0,0,0.11) !important;
  border-radius: 5px;
  width: 150px;
  margin: 8px 5px;
}

.prodcard-2, .cate-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(164, 164, 164, 0.25);
  border-radius: 5px;
  width: 46%;
  margin: 8px 5px;
  display: flex;
  flex-direction: column;
}

path, rect, polygon {
  cursor: pointer;
}

.prodcard-img {
  width: 200px;
  max-width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0 ;
}

.prodcard-img-sm {
  pointer-events: none;
  width: 100% ; 
  height: 120px;
  object-fit: cover;
  border-radius: 8px 8px 0 0 ;
}

.prodcard-vid {
  width: 200px !important; 
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0 0 ;
}

.prodcard-title {
  font-size: 14px;
  font-weight: 700; 
  color: #3C3C3C;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 5px;
}

.prodcard-subtitle {
  font-size: 10px;
  color: #9C9C9C;
  letter-spacing: 0;
  margin: 5px 0
}

.enq-box {
  padding: 10px 20px;
  text-align: center;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
  color: #EC2034;
  position: fixed;
  bottom: 0;
  margin: auto;
  width: 100%;
  background: white;
}


/* -------------- Draggable gameification icon ---------------- */
.container {
  height: calc(100vh - 80px);
  width: calc(100vw - 0px);
  padding-top: 20px;
  position: fixed;
  pointer-events: none;
  z-index: 1;
}

.gameicon {
  position: fixed;
  right: 0;
  bottom: 80px;
  pointer-events: auto;
}

.xbtn {
  float: right;
  background: transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

.draggable {
  cursor: -webkit-grab;
  cursor: grab;
}

.uk-input,
.uk-textarea,
.uk-checkbox {
  margin-bottom: 10px;
}
/* --------------- Game Page CSS ----------------- */
.gameContainer {
  position: relative;
  background: url('./images/bg2.jpg');
  /* background-color: rgba(255, 255, 255, 0.7) !important;
  background-blend-mode: color; */
  background-size: cover;
  background-position: center;
  margin: auto;
  width: 100vw;
  height: calc(100vh - 120px);
}

.game-title {
  font-size: 13px;
  line-height: 22px;
  font-weight: 700;
  color: #8E764B;
  padding-top: 30px;
  padding-bottom: 30px;
}

.egg-div {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  overflow: auto;
}

.tutorial-video {
  margin: 0 auto;
  padding: 15px 0;
}

.the-mighty-egg {
  margin: auto;
  margin-top: 10px !important;
  max-height: 35vh;
  max-width: 35vh;
  cursor: pointer;
}

@media (max-width: 968px) {
  .tutvid {
    margin-bottom: 20px;
  }
}

@media (min-width: 968px) {
  .gameContainer {
    height: calc(100vh - 170px);
  }

  .tutorial-video {
    padding: 15px 0 30px;
    max-height: 40vh;
  }

  .the-mighty-egg {
    max-height: 50vh;
    max-width: 50vh;
  }
  
}

.the-mighty-egg:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: 2;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes glow {
  from {
    box-shadow: 0px 0px 0.5px 1px rgba(255, 0, 0, 1);
  }
  to {
    box-shadow: 0px 0px 1px 2px rgba(255, 0, 0, 1);
  }
}

.game-modal {
  background: rgba(255, 255, 255, 0.9);
}

.game-modal-body .modal-content {
  background: none !important;
  border: none;
}

.game-modal-body .close {
  color: #000
}

.toplogo {
  padding-top: 50px;
  width: 70px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.sponsor {
  overflow-x: auto;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  background: #FFFFFF;
}

.MuiSlider-root {
  padding: 0 !important;
}
